export const injectScript = () => {
    const gplusjs = '(function (d) {var s = d.createElement("script");s.setAttribute("data-account", "Yd9gTOcFbs");s.setAttribute("src", "https://cdn.userway.org/widget.js");(d.body || d.head).appendChild(s);})(document)';
    const script = document.createElement('script');
    script.textContent = gplusjs;
    document.body.appendChild(script);
};
export const userWayEvents = [
    's2', // Big cursor pointer
    's3', // Contrast
    's4', //Big text
    's6', // Highlight feature
    's7', // Legible fonts
    's10', // Reading Guide
    's12', //Tooltips
    's13', // Pause animations
    's14', // Text spacing
    's15', //Dyslexia Font
    's16', //Mask reader
    's17', // Line height
    's19', // text align
    's23', // Saturation
];
export const userWayConfig = {
    s2: {
        disabled: `window.UserWay.bigCursorDisable()`,
        enabled: `window.UserWay.bigCursorEnable()`,
    },
    s3: {
        disabled: `window.UserWay.contrastDisable()`,
        enabled: `window.UserWay.contrastEnable(parseInt(value))`,
    },
    s4: {
        disabled: `window.UserWay.bigTextDisable()`,
        enabled: `window.UserWay.bigTextEnable(parseInt(value))`,
    },
    s6: {
        disabled: `window.UserWay.highlightDisable()`,
        enabled: `window.UserWay.highlightToggle()`,
    },
    s7: {
        disabled: `window.UserWay.legibleFontsDisable()`,
        enabled: `window.UserWay.legibleFontsToggle()`,
    },
    s10: {
        disabled: `window.UserWay.readingGuideDisable()`,
        enabled: `window.UserWay.readingGuideEnable()`,
    },
    s12: {
        disabled: `window.UserWay.tooltipsDisable()`,
        enabled: `window.UserWay.tooltipsToggle()`,
    },
    s13: {
        disabled: `window.UserWay.stopAnimationDisable()`,
        enabled: `window.UserWay.stopAnimationToggle()`,
    },
    s14: {
        disabled: `window.UserWay.textSpacingDisable()`,
        enabled: `(function enable(){
        const input = document.querySelector('.${"message-input" /* TestClassName.MessageInput */}');
        input.classList.add("userway-s14-active");
        window.UserWay.textSpacingEnable(parseInt(value));
      })();
        `,
    },
    s15: {
        disabled: `window.UserWay.dyslexiaFontDisable()`,
        enabled: `window.UserWay.dyslexiaFontToggle()`,
    },
    s16: { disabled: `window.UserWay.readingMaskDisable()` },
    s17: {
        disabled: `window.UserWay.lineHeightDisable()`,
        enabled: `window.UserWay.lineHeightEnable(parseInt(value))`,
    },
    s19: {
        disabled: `window.UserWay.textAlignDisable()`,
        enabled: `window.UserWay.textAlignEnable(parseInt(value))`,
    },
    s23: {
        disabled: `window.UserWay.saturationDisable()`,
        enabled: `window.UserWay.saturationEnable(parseInt(value))`,
    },
};
