/** User provided configuration */
export var SessionStatus;
(function (SessionStatus) {
    SessionStatus["Open"] = "open";
    SessionStatus["Resolved"] = "closed";
    SessionStatus["Expired"] = "expired";
    SessionStatus["Spam"] = "spam";
    SessionStatus["Trash"] = "trash";
})(SessionStatus || (SessionStatus = {}));
/**
 * Events send from the iframe to the web-client
 */
export var IframeEventType;
(function (IframeEventType) {
    IframeEventType["IframeLoaded"] = "moveo-iframe-loaded";
    IframeEventType["IframeError"] = "moveo-iframe-error";
    IframeEventType["WidgetLoaded"] = "moveo-widget-loaded";
    IframeEventType["WidgetError"] = "moveo-widget-error";
    IframeEventType["Opened"] = "moveo-widget-opened";
    IframeEventType["Closed"] = "moveo-widget-closed";
    IframeEventType["Resize"] = "moveo-widget-resize";
    IframeEventType["LocaleChanged"] = "moveo-widget-locale-changed";
    IframeEventType["SessionCreated"] = "moveo-widget-session-created";
    IframeEventType["SessionReconnected"] = "moveo-widget-session-reconnected";
    IframeEventType["SessionClosed"] = "moveo-widget-session-closed";
    IframeEventType["ConversationClosed"] = "moveo-widget-conversation-closed";
    IframeEventType["LinkClicked"] = "moveo-widget-link-clicked";
    IframeEventType["ConversationUnreadMessages"] = "moveo-conversation-unread-messages";
    IframeEventType["ConversationNoUnreadMessages"] = "moveo-conversation-no-unread-messages";
    IframeEventType["UserWayInit"] = "moveo-userway-init";
})(IframeEventType || (IframeEventType = {}));
/**
 * Events send from the web-client to the iframe
 */
export var WebClientEventType;
(function (WebClientEventType) {
    WebClientEventType["SetConfig"] = "moveo-set-config";
    WebClientEventType["SetLocale"] = "moveo-set-locale";
    WebClientEventType["SetCssVariables"] = "moveo-set-css-variables";
    WebClientEventType["Open"] = "moveo-open";
    WebClientEventType["Close"] = "moveo-close";
    WebClientEventType["SendMessage"] = "moveo-send-message";
    WebClientEventType["UpdateContext"] = "moveo-update-context";
    WebClientEventType["WindowResize"] = "moveo-resize";
    WebClientEventType["SessionActivity"] = "session-activity";
    WebClientEventType["UserWayDisabled"] = "userway-disabled";
    WebClientEventType["UserWayEnabled"] = "userway-enabled";
    WebClientEventType["LinkClicked"] = "link-clicked";
})(WebClientEventType || (WebClientEventType = {}));
export var ChatModal;
(function (ChatModal) {
    ChatModal["Expired"] = "expired";
    ChatModal["Survey"] = "survey";
    ChatModal["SurveyFull"] = "survey-full";
    /**
     * Shows a modal with a button to close the session.
     * This is shown when the user press the close button on the widget.
     */
    ChatModal["Closing"] = "closing";
    /**
     * Shows the resolved modal with a button to continue or end the session.
     * This is shown when the agent closes the conversation.
     */
    ChatModal["Resolved"] = "closed";
    ChatModal["Inactivity"] = "inactivity";
})(ChatModal || (ChatModal = {}));
export var TestClassName;
(function (TestClassName) {
    TestClassName["MinimizeButton"] = "minimize-btn";
    TestClassName["CloseButton"] = "close-btn";
    TestClassName["EndSessionButton"] = "end-btn";
    TestClassName["LauncherButton"] = "launcher-btn";
    TestClassName["MessageInput"] = "message-input";
    TestClassName["DisclaimerCheck"] = "disclaimer-check";
    TestClassName["DisclaimerContainer"] = "disclaimer-container";
    TestClassName["HeaderContainer"] = "header-container";
    TestClassName["ImageButton"] = "image-btn";
})(TestClassName || (TestClassName = {}));
